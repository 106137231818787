import i18n from "@/libs/i18n";

export default [
  {
    header: i18n.t("Customer"),
  },
  {
    title: i18n.t("Customer Registation"),
    icon: "FilePlusIcon",
    children: [
      {
        title: i18n.t("Customer Registation All"),
        route: "customer-All",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Customer Registation Goverment"),
        route: "customer-goverment",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Customer Registation Private"),
        route: "customer-private",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Customer Registation Clinic"),
        route: "customer-clinic",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Customer Registation Donator"),
        route: "customer-donator",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Customer Registation Sub-Dealer"),
        route: "customer-sub-dealer",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Customer Registation Others"),
        route: "customer-others",
        icon: "FilePlusIcon",
      },
    ],
  },
  {

    header: i18n.t("Vendor"),
  },
  {
    title: i18n.t("Supplier Registation"),
    icon: "FileIcon",
    children: [
      {
        title: i18n.t("Supplier Registation All"),
        route: "vendor",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Supplier Registation Oversea"),
        route: "vendor-oversea",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Supplier Registation Domestic"),
        route: "vendor-domestic",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Supplier Registation Transportation"),
        route: "vendor-transportation",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Supplier Registation Shipping Forwarder"),
        route: "vendor-shipping-forwarder",
        icon: "FilePlusIcon",
      },
      {
        title: i18n.t("Supplier Registation Account"),
        route: "vendor-account",
        icon: "FilePlusIcon",
      },
    ]
  },    
  {
    header: i18n.t("Purchase"),
  },
  {
    title: i18n.t("Dashboards"),
    icon: "TrendingUpIcon",
    route: "purchase-dashboard",
  },
  {
    title: i18n.t("activity-log"),
    icon: "",
    
    children: [
      {
        title: i18n.t("Table Log"),
        route: "purchase-Log",
        icon: "",
      },
      {
        title: i18n.t("add-Log"),
        route: "add-Log",
        icon: "",
      },
    
    ],
    
  },
  {
    title: i18n.t("Purchase Request"),
    icon: "EditIcon",
    children: [
      {
        title: i18n.t("Person Purchase Request Table"),
        route: "purchase-request-domestic",
        icon: "",
      },
      {
        title: i18n.t("All Purchase Request"),
        route: "all-purchase-request-domestic",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Purchase Request Out"),
    icon: "EditIcon",
    children: [
      {
        title: i18n.t("Person Purchase Request Table Out"),
        route: "purchase-request-oversea",
        icon: "",
      },
      {
        title: i18n.t("All Purchase Request Out"),
        route: "all-purchase-request-oversea",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Purchase Order"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Purchase Order Table"),
        route: "purchase-order-domestic",
        icon: "",
      },
      {
        title: i18n.t("All Purchase Order"),
        route: "all-purchase-order-domestic",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Purchase Order Out"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Purchase Order Table Out"),
        route: "purchase-order-oversea",
        icon: "",
      },
      {
        title: i18n.t("All Purchase Order"),
        route: "all-purchase-order-oversea",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Compare"),
    icon: "RefreshCcwIcon",
    children: [
      {
        title: i18n.t("Person Compare Table"),
        route: "purchase-compare",
        icon: "",
      },
      {
        title: i18n.t("New Compare Table"),
        route: "new-purchase-compare",
        icon: "",
      },
      {
        title: i18n.t("All Compare Table"),
        route: "all-purchase-compare",
        icon: "",
      },
      {
        title: i18n.t("Summary"),
        route: "summary-lists",
        icon: "",
      },
    ],
  },
  {
    header: i18n.t("Warehouse"),
  },
  {
    title: i18n.t("Dashboard"),
    icon: "TrendingUpIcon",
    route: "inventory-dashboard",
  },
  {
    title: i18n.t("Product Master"),
    icon: "HardDriveIcon",
    route: "product-master",
  },
  {
    title: i18n.t("Add Cost Item"),
    icon: "DollarSignIcon",
    route: "add-cost-item",
  },
  {
    title: i18n.t("Show Cost Item"),
    icon: "FileTextIcon",
    route: "cost",
  },
  {
    title: i18n.t("Tracking Item"),
    icon: "SearchIcon",
    children: [
      {
        title: i18n.t("Search Item"),
        route: "item",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Import Item"),
    icon: "DownloadIcon",
    children: [
      {
        title: i18n.t("Person Import Item"),
        route: "person-import-approval",
        icon: "",
      },
      {
        title: i18n.t("All Import Item"),
        route: "all-import-approval",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Export Item"),
    icon: "TruckIcon",
    children: [
      {
        title: i18n.t("Person Export Item"),
        route: "person-table-export",
        icon: "",
      },
      {
        title: i18n.t("All Export Item"),
        route: "all-table-export",
        icon: "",
      },
    ],
  },

  {
    title: i18n.t("Stock Item"),
    icon: "PackageIcon",
    children: [
      {
        title: i18n.t("Stock Lists"),
        route: "stock-lists",
        icon: "",
      },
      {
        title: i18n.t("Import By IR All"),
        route: "import-by-ir-all",
        icon: "",
      },
      {
        title: i18n.t("Import By IR Person"),
        route: "import-by-ir-person",
        icon: "",
      },
      {
        title: i18n.t("Export All"),
        route: "picking-list-all",
        icon: "",
      },
      {
        title: i18n.t("Export Person"),
        route: "picking-list-person",
        icon: "",
      },
      {
        title: i18n.t("Export Log"),
        icon: "",
        route: "export-log",
      },
      {
        title: i18n.t("Export POS"),
        route: "export-pos",
        icon: "",
      },

      {
        title: i18n.t("Item Exports"),
        icon: "",
        route: "inven-stock",
      },
      {
        title: i18n.t("Return POS"),
        route: "return-pos",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Packaging"),
    icon: "PackageIcon",
    children: [      
      {
        title: i18n.t("Person Packaging"),
        route: "person-table-packaging",
      },
      {
        title: i18n.t("All Packaging"),
        route: "all-table-packaging",
      },
    ]
  },
  {
    header: i18n.t("Customer Service"),
  },
  {
    title: i18n.t("Customer Service"),
    icon: "UsersIcon",
    children: [      
      {
        title: i18n.t("Request Service Inhouse All"),
        route: "service-cal-all",
        icon: "",
      },
      {
        title: i18n.t("Request Service Inhouse Person"),
        route: "service-cal-person",
        icon: "",
      },
    ]
  },
  {
    title: i18n.t("Service"),
    icon: "UsersIcon",
    children: [
      {
        title: i18n.t("Person Table Service"),
        route: "person-table-service",
        icon: "",
      },
      {
        title: i18n.t("All Table Service"),
        route: "all-table-service",
        icon: "",
      },
    ],
  },
  {
    header: i18n.t("Transportation"),
  },
  {
    title: i18n.t("Transportation"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Transportation"),
        route: "person-table-transportation",
        icon: "",
      },
      {
        title: i18n.t("All Table Transportation"),
        route: "all-table-transportation",
        icon: "",
      },
    ],
  },
  {
    header: i18n.t("Project"),
  },
  {
    title: i18n.t("Dashboards"),
    icon: "TrendingUpIcon",
    route: "project-dashboard",
  },
  {
    title: i18n.t("Project-Title"),
    icon: "FileIcon",
    children: [
      {
        title: i18n.t("Project-PMS"),
        route: "project-pms",
        icon: "",
      },
      {
        title: i18n.t("Project-UIS"),
        route: "project-uis",
        icon: "",
      },
      {
        title: i18n.t("Project-CSV"),
        route: "project-csv",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Approve Job-PMS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Approve Job-PMS"),
        route: "Person-Table-Approve-PMS",
        icon: "",
      },
      {
        title: i18n.t("All Table Approve Job-PMS"),
        route: "All-Table-Approve-PMS",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Approve Job-UIS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Approve Job-UIS"),
        route: "Person-Table-Approve-UIS",
        icon: "",
      },
      {
        title: i18n.t("All Table Approve Job-UIS"),
        route: "All-Table-Approve-UIS",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Approve Job-CSV"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Approve Job-CSV"),
        route: "Person-Table-Approve-CSV",
        icon: "",
      },
      {
        title: i18n.t("All Table Approve Job-CSV"),
        route: "All-Table-Approve-CSV",
        icon: "",
      },
    ],
  },
  
  


  {
    header: i18n.t("admin"),
  },

  {
    title: i18n.t("Quotation PMS Admin"),
    // route: "admin-table-quotation-pms",
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Quotation PMS"),
        route: "admin-person-table-quotation-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table Quotation PMS"),
        route: "admin-table-quotation-pms",
        icon: "",
      },
    ]
  },
  {
    title: i18n.t("Quotation UIS Admin"),
    // route: "admin-table-quotation-uis",
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Quotation UIS"),
        route: "admin-person-table-quotation-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table Quotation UIS"),
        route: "admin-table-quotation-uis",
        icon: "",
      },
    ]
  },
  {
    title: i18n.t("Quotation CSV Admin"),
    route: "admin-table-quotation-csv",
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Quotation CSV"),
        route: "admin-person-table-quotation-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table Quotation CSV"),
        route: "admin-table-quotation-csv",
        icon: "",
      },
    ]
  },
  {
    header: i18n.t("Sales & Marketing"),
  },

  {
    title: i18n.t("Dashboards"),
    icon: "TrendingUpIcon",
    route: "sale-dashboard",
  },
  {
    title: i18n.t("Close Job PMS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Close Job PMS"),
        route: "person-table-close-job-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table Close Job PMS"),
        route: "all-table-close-job-pms",
        icon: "",
      },
    ]
  },
  {
    title: i18n.t("Close Job UIS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Close Job UIS"),
        route: "person-table-close-job-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table Close Job UIS"),
        route: "all-table-close-job-uis",
        icon: "",
      },
    ]
  },
  {
    title: i18n.t("Close Job CSV"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Close Job CSV"),
        route: "person-table-close-job-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table Close Job CSV"),
        route: "all-table-close-job-csv",
        icon: "",
      },
    ]
  },
  {
    title: i18n.t("Quotation PMS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Quotation PMS"),
        route: "person-table-quotation-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table Quotation PMS"),
        route: "all-table-quotation-pms",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Quotation UIS"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Quotation UIS"),
        route: "person-table-quotation-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table Quotation UIS"),
        route: "all-table-quotation-uis",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Quotation CSV"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Quotation CSV"),
        route: "person-table-quotation-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table Quotation CSV"),
        route: "all-table-quotation-csv",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Orders PMS"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Orders"),
        route: "person-table-order-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table Orders"),
        route: "all-table-order-pms",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Orders UIS"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Orders"),
        route: "person-table-order-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table Orders"),
        route: "all-table-order-uis",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Orders CSV"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Orders"),
        route: "person-table-order-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table Orders"),
        route: "all-table-order-csv",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Back Orders PMS"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Back Orders"),
        route: "person-table-back-order-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table Back Orders"),
        route: "all-table-back-order-pms",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Back Orders UIS"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Back Orders"),
        route: "person-table-back-order-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table Back Orders"),
        route: "all-table-back-order-uis",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Back Orders CSV"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Back Orders"),
        route: "person-table-back-order-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table Back Orders"),
        route: "all-table-back-order-csv",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Borrow PMS"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Borrow"),
        route: "person-table-borrow-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table Borrow"),
        route: "all-table-borrow-pms",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Borrow UIS"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Borrow"),
        route: "person-table-borrow-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table Borrow"),
        route: "all-table-borrow-uis",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Borrow CSV"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Borrow"),
        route: "person-table-borrow-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table Borrow"),
        route: "all-table-borrow-csv",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Return PMS"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Return"),
        route: "person-table-return-pms",
        icon: "",
      },
      {
        title: i18n.t("All Table Return"),
        route: "all-table-return-pms",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Return UIS"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Return"),
        route: "person-table-return-uis",
        icon: "",
      },
      {
        title: i18n.t("All Table Return"),
        route: "all-table-return-uis",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Return CSV"),
    icon: "ShareIcon",
    children: [
      {
        title: i18n.t("Person Table Return"),
        route: "person-table-return-csv",
        icon: "",
      },
      {
        title: i18n.t("All Table Return"),
        route: "all-table-return-csv",
        icon: "",
      },
    ],
  },
  {
    header: i18n.t("Accounting & Finance"),
  },
  {
    title: i18n.t("Dashboards"),
    icon: "TrendingUpIcon",
    route: "accounting-dashboard",
  },
  {
    title: i18n.t("Operation Table Borrow"),
    route: "operation-table-borrow",
    icon: "FileTextIcon",
  },
  {
    title: i18n.t("Invoice Sale"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Invoice Sale"),
        route: "person-table-invoice-sale",
        icon: "",
      },
      {
        title: i18n.t("All Table Invoice Sale"),
        route: "all-table-invoice-sale",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Invoice Service"),
    icon: "FileTextIcon",
    children: [
      {
        title: i18n.t("Person Table Invoice Service"),
        route: "person-table-invoice-service",
        icon: "",
      },
      {
        title: i18n.t("All Table Invoice Service"),
        route: "all-table-invoice-service",
        icon: "",
      },
    ],
  },
  {
    title: i18n.t("Cost"),
    icon: "DollarSignIcon",
    children: [
      {
        title: i18n.t("Cost Table"),
        route: "cost-table",
        icon: "",
      },
      {
        title: i18n.t("Cost Calendar"),
        route: "cost-calendar",
        icon: "",
      },
    ],
  },
  // {
  //   title: "Dashborad",
  //   icon: "TrendingUpIcon",
  //   route: "inventory-dashboard",
  // },
  {
    header: i18n.t("Setting"),
  },
  {
    title: i18n.t("Exchange Rate"),
    icon: "DollarSignIcon",
    route: "setting-exchange-rate",
  },
  {
    title: i18n.t("Generate Code"),
    icon: "HashIcon",
    route: "setting-generate-code",
  },
  {
    title: i18n.t("User Approve"),
    icon: "HashIcon",
    route: "setting-user-approve",
  },
  {
    title: i18n.t("Payment"),
    icon: "DollarSignIcon",
    route: "setting-payment",
  },
  {
    title: i18n.t("Setting App"),
    icon: "SettingsIcon",
    route: "setting-app",
  },
  {
    title: i18n.t('Configuration Template'),
    route: 'template-configuration',
    icon: 'PackageIcon',
  },
  {
    title: i18n.t('ProductCodeMaster'),
    icon: 'KeyIcon',
    children: [
      {
        title: i18n.t('Main Product List'),
        route: 'product-code-main-lists',
        icon: '',
      },
      {
        title: i18n.t('Department List'),
        route: 'product-code-department-lists',
        icon: '',
      },
      {
        title: i18n.t('Product Group List'),
        route: 'product-code-group-lists',
        icon: '',
      },
      {
        title: i18n.t('Product Type List'),
        route: 'product-code-type-lists',
        icon: '',
      },
      {
        title: i18n.t('Brand List'),
        route: 'product-code-brand-lists',
        icon: '',
      },
      {
        title: i18n.t('Model List'),
        route: 'product-code-model-lists',
        icon: '',
      },
    ],
  },
  {
    title: i18n.t('Unit Master'),
    icon: 'KeyIcon',
    children: [
      {
        title: i18n.t('Unit Master Table'),
        route: 'unit',
        icon: '',
      },
    ],
  },
  {
    title: i18n.t('Site Master'),
    icon: 'KeyIcon',
    children: [
      {
        title: i18n.t('Site Master Table'),
        route: 'site',
        icon: '',
      },
    ],
  },
  {
    title: i18n.t('Item Position Master'),
    icon: 'KeyIcon',
    children: [
      {
        title: i18n.t('Item Position Master Table'),
        route: 'item-position',
        icon: '',
      },
    ],
  },
  {
    title: i18n.t('Category'),
    icon: 'KeyIcon',
    children: [
      {
        title: i18n.t('Category List'),
        route: 'category-lists',
        icon: '',
      },
    ],
  },
];

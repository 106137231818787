import i18n from '@/libs/i18n'

export default [
  {
    header: i18n.t('Management'),
  },
  {
    title: i18n.t('Users Management'),
    route: 'user-lists',
    icon: 'PlusSquareIcon',
  },
  {
    title: i18n.t('Role App'),
    route: 'role',
    icon: 'UserIcon',
  },
  {
    title: i18n.t('Position'),
    icon: 'KeyIcon',
    children: [
      {
        title: i18n.t('Position List'),
        route: 'position-lists',
        icon: 'KeyIcon',
      },
    ],
  },
  {
    title: i18n.t('UserArea'),
    icon: 'KeyIcon',
    children: [
      {
        title: i18n.t('UserArea List'),
        route: 'userarea',
        icon: 'KeyIcon',
      },
    ],
  },
]
